import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link as RouteLink, Redirect } from 'react-router-dom';
import { Element } from 'react-scroll';

import { BreakpointProvider } from 'react-socks';

import './App.css';

import AppContents from './components/AppContents';

class App extends Component {

  constructor() {
    super();
    this.state = {
      language: 'en'
    }
    this.changeLanguage.bind(this.changeLanguage);
  }

  changeLanguage = () => {
    if (this.state.language == 'es') {
        this.setState({
            language: 'en'
        }, 
        )
    } else {
        this.setState({
            language: 'es'
        }, )
    }
  }

  callAPI() {
    fetch('/api/episodes')
      .then(res => {
        const apiResponse = res.data;
        this.setState({ apiResponse });
      })
  }

  componentDidMount() {
    this.callAPI();
  }

  render() {
    return (
      <Router>
      <BreakpointProvider>
        <Route exact path='/' render={props => (
          <AppContents language={this.state.language} changeLanguage={this.changeLanguage} />
          )} />
        <Route exact path='/es' render={props => (
          <AppContents language={this.state.language} changeLanguage={this.changeLanguage} />
          )} />
      </BreakpointProvider>
      </Router>
    );
    
  }
}

export default App;
