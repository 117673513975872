import React, { Component } from 'react';

import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';
import SlideContents from './SlideContents';

class Slideshow extends Component {

    constructor(props) {
        super(props);
        this.dimmerRef = React.createRef();
    }

    preventDragHandler = (e) => {
        e.preventDefault();
    }

    darkenAnimation(e) {
        this.dimmerRef.current.style.opacity = '0.6';
    }

    lightenAnimation(e) {
        this.dimmerRef.current.style.opacity = '0.0';
    }    

    render() {
        return (
            <div>
                <Slide easing="ease">
              {
                  this.props.episodes.reverse().slice(0, 5).map((episode) => {
                      return (
                        <SlideContents episode={episode} showAudioPlayer={this.props.showAudioPlayer} setCurrentEpisode={this.props.setCurrentEpisode} language={this.props.language} />
                      )
                  })
                  
              }
              </Slide>
          </div>
          )
    } 
};

export default Slideshow;