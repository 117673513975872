import React, { Component, Fragment } from 'react';

import { Breakpoint } from 'react-socks';

import SocialMediaItem from './SocialMediaItem';

class SocialMedia extends Component {

    render() {

        const socialMediaContents =
        <Fragment>
            <div style={horizontalDivider} />
            <div style={mediaHeader}>
                <p style={mediaHeaderText}>Social Media</p>
            </div>    
            <div>
                <SocialMediaItem name="Spotify" img="/images/icons/spotify.png" link={"https://open.spotify.com/"} hoverColor={'#1DB954'} />
                <SocialMediaItem name="Apple" img="/images/icons/apple.png" link={"https://apps.apple.com/us/app/apple-podcasts/id525463029"} hoverColor={'#ddd'} />
                <SocialMediaItem name="Twitter" img="/images/icons/twitter.png" link={"https://twitter.com/explore"} hoverColor={'#00acee'}/>
            </div>
            <div style={horizontalDivider} />
        </Fragment>

        return(
            <Fragment>
                <Breakpoint customQuery="(min-width: 1217px)">
                    <div style={mediaDiv}>
                        {socialMediaContents}
                    </div>
                </Breakpoint>
                <Breakpoint customQuery="(max-width: 1216px)">
                    <div style={mediaDivMedium}>
                        {socialMediaContents}
                    </div>
                </Breakpoint>
            </Fragment>
        )
    }
}

const mediaDiv = {
    width: '300px',
    height: '260px',
    paddingTop: '6px',
    marginTop: '154px'
}

const mediaDivMedium = {
    width: '300px',
    height: '260px',
    paddingTop: '6px',
    marginTop: '0px'
}

const horizontalDivider = {
    height: '1px',
    width: '90%',
    backgroundColor: '#999',
    margin: 'auto'
}

const mediaHeader = {
    width: '100%',
    height: '20px',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '32px',
    margin: '32px 0px 10px 0px',
}

const mediaHeaderText = {
    lineHeight: '0px',
    backgroundColor: 'yellow'
}

export default SocialMedia;