import React, { Component } from 'react';

class SocialMediaItem extends Component {
    constructor(props) {
        super(props);
        this.hoverRef = React.createRef();
    }
    
    darkenAnimation(e) {
        this.hoverRef.current.style.backgroundColor = this.props.hoverColor;
    }

    lightenAnimation(e) {
        this.hoverRef.current.style.backgroundColor = '#fff';
    }    

    render() {
        return(
            <a href={this.props.link} target='_blank' style={socialMediaLink}>
                <div style={mediaLink} ref={this.hoverRef} onMouseEnter={this.darkenAnimation.bind(this)} onMouseLeave={this.lightenAnimation.bind(this)}>
                    <div style={mediaLinkWrapper}>
                        <p style={mediaLinkText}>{this.props.name}</p> 
                        <img src={process.env.PUBLIC_URL + this.props.img} alt={this.props.name} style={mediaIcon} />
                    </div> 
                </div>
            </a>
        )
    }
}

const mediaLink = {
    height: '40px',
    width: '90%',
    backgroundColor: '#fff',
    margin: '0px auto',
    padding: '0px 0px 20px 0px',
    borderRadius: '2px',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '24px',
    backgroundColor: '#fff',
    transition: 'background-color .5s'
}

const mediaLinkWrapper = {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    margin: 'auto',
}

const mediaIcon = {
    height: '26px',
    marginTop: '14px'
}

const mediaLinkText = {
    height: '26px',
    marginTop: '16px',
    marginRight: '8px'
}

const socialMediaLink = {
    color: 'black',
}


export default SocialMediaItem;