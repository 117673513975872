import React, { Component, Fragment } from 'react';

import Slideshow from './Slideshow';

import { Breakpoint } from 'react-socks';

class Highlights extends Component {
    render() {
        return(
            <Fragment>
            <Breakpoint customQuery="(min-width: 700px)">
            <div style={highlightsDiv}>
                <div style={slideshowWrapper}>
                    <Slideshow episodes={this.props.episodes} showAudioPlayer={this.props.showAudioPlayer} setCurrentEpisode={this.props.setCurrentEpisode} language={this.props.language} />
                </div> 
            </div>
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 699px)">
            <div style={highlightsDivMobile}>
                <div style={slideshowWrapper}>
                    <Slideshow episodes={this.props.episodes} showAudioPlayer={this.props.showAudioPlayer} setCurrentEpisode={this.props.setCurrentEpisode} language={this.props.language} />
                </div> 
            </div>
            </Breakpoint>
            </Fragment>
        )
    }
}

const highlightsDiv = {
    width: '100%',
    height: '650px',
}

const highlightsDivMobile = {
    width: '100%',
    height: '300px',
}

const slideshowWrapper = {
    width: '100%',
    height: '100%',
    margin: 'auto',
}

export default Highlights;