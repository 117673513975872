import React, { Component } from 'react';

import Episode from './Episode'

class Episodes extends Component {

    constructor(props) {
        super(props);
    }

    assignColor(season) {
        const colors = ['#ca98ed', '#f06d35','#aaf0d9','#fc9fcc','#7dbf78','#f5dd64']
        return colors[(season - 1) % 6];
    }

    render() {

        const eps = this.props.episodes.filter((epi)=>{
            if(this.props.selected_season == 0)
                return epi
            else if( epi.season == this.props.selected_season ){
                return epi
            }
          })

        return(
            <div style={episodesWrapper}>
                <div style={episodesDiv}>
                {
                //
                eps.reverse().slice(0, this.props.episodes_shown).map((epi) => {
                    return(
                        <Episode key={epi.id} episode={epi} showAudioPlayer={this.props.showAudioPlayer} setCurrentEpisode={this.props.setCurrentEpisode} color={this.assignColor(epi.season)}/>
                    )
                })}
                </div> 
            { 
            this.props.episodes_shown < eps.length &&
                <div style={loadMoreButton} onClick={this.props.loadMore}>
                    <p style={loadMoreText}>Load More</p>
                </div>
            }
            
            </div>
        )
    }
}

const episodesWrapper = {
    display: 'flex',
    flexFlow: 'column nowrap',
    
}

const episodesDiv = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center'
}

const loadMoreButton = {
    width: '200px',
    height: '50px',
    backgroundColor: 'lightblue',
    borderRadius: '10px',
    margin: '20px auto 20px auto',
    cursor: 'pointer'
}

const loadMoreText = {
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '30px',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    marginTop: '9px'
}

export default Episodes;