import React, { Component } from 'react';

import { Element } from 'react-scroll';

import Header from './Header';
import Highlights from './slideshow/Highlights';
import About from './About';
import EpisodesContainer from './episodes/EpisodesContainer';
import Footer from './Footer';

import AudioPlayer from './AudioPlayer';

import data from '../data/data.json'

class AppContents extends Component {

  constructor() {
    super();
    this.state = data;
    this.showAudioPlayer = this.showAudioPlayer.bind(this);
    this.hideAudioPlayer = this.hideAudioPlayer.bind(this);
    this.setCurrentEpisode = this.setCurrentEpisode.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.state = {
      ...this.state,
      language: 'en'
    }
  }

  showAudioPlayer(e) {
    this.setState({
      show: true
    })
  }

  hideAudioPlayer(e) {
    this.setState({
      show: false
    })
  }

  setCurrentEpisode = (curEp) => {
    this.setState({
      current_episode: curEp
    })
  }

  changeLanguage = () => {
    if (this.state.language == 'es') {
        this.setState({
            language: 'en'
        },
        )
    } else {
        this.setState({
            language: 'es'
        })
    }
  }

  render() {
    return (
          <div className="App">
            <Header language={this.props.language} changeLanguage={this.props.changeLanguage} />
      
            <AudioPlayer show={this.state.show} episode={this.state.current_episode} hideAudioPlayer={this.hideAudioPlayer} />

            <Highlights episodes={this.state.episodes} showAudioPlayer={this.showAudioPlayer} setCurrentEpisode={this.setCurrentEpisode} language={this.props.language}/>

            <Element name="about" className="about">
              <About language={this.props.language} />
            </Element>
      
            <Element name="episodes" className="episodes">
              <EpisodesContainer episodes={this.state.episodes} showAudioPlayer={this.showAudioPlayer} setCurrentEpisode={this.setCurrentEpisode}/>
            </Element>

            <Footer language={this.props.language} changeLanguage={this.props.changeLanguage} />
        </div>
    );
  }
}

export default AppContents;
