// Modal Component

import React, { Component, Fragment } from 'react';

import ReactAudioPlayer from 'react-audio-player';
import Moment from 'react-moment';

import { Breakpoint } from 'react-socks';

import Truncate from 'react-truncate';

class AudioPlayer extends Component {
    
    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <Fragment>
                <Breakpoint customQuery="(min-width: 650px)">
                <div style={dimmerDiv} onClick={this.props.hideAudioPlayer} >
                </div>
                <div style={audioPlayerDiv}>
                    <div style={topWrapper}>
                        <div style={headerWrapper}>
                            <p style={audioPlayerHeader}>{this.props.episode.title}</p>
                        </div>
                        <div style={horizontalDivider} />
                        <div style={descriptionWrapper} className='noscroll'>
                            <p style={audioPlayerDescription}>{this.props.episode.description}</p>
                        </div>
                    </div>
                    <div style={bottomWrapper}>
                        <div style={detailsWrapper}>
                            <p style={audioPlayerDetails}>Published on <Moment date={this.props.episode.date} format="MMMM D, YYYY" /></p>
                            <p style={audioPlayerDetails}>Recorded in English</p>
                        </div>
                        <ReactAudioPlayer src={process.env.PUBLIC_URL + `/audios/${this.props.episode.audio}`}
                        controls
                        style = {playerStyle}
                        />
                    </div>
                </div>
                </Breakpoint>
                <Breakpoint customQuery="(max-width: 649px)">
                <div style={dimmerDiv} onClick={this.props.hideAudioPlayer} >
                </div>
                <div style={audioPlayerDivMobile}>
                    <div style={topWrapper}>
                        <div style={headerWrapper}>
                            <p style={audioPlayerHeaderMobile}>{this.props.episode.title}</p>
                        </div>
                        <div style={horizontalDivider} />
                        <div style={descriptionWrapper} className='noscroll'>
                            <p style={audioPlayerDescriptionMobile}>{this.props.episode.description}</p>
                        </div>
                    </div>
                    <div style={bottomWrapper}>
                        <div style={detailsWrapper}>
                            <p style={audioPlayerDetailsMobile}>Published on <Moment date={this.props.episode.date} format="MMMM D, YYYY" /></p>
                            <p style={audioPlayerDetailsMobile}>Recorded in {this.props.episode.language}</p>
                        </div>
                        <ReactAudioPlayer src={process.env.PUBLIC_URL + `/audios/${this.props.episode.audio}`}
                        controls
                        style = {playerStyle}
                        />
                    </div>
                    
                </div>
                </Breakpoint>
            </Fragment>
        )
    }
}

const dimmerDiv = {
    position: 'fixed',
    zIndex: '900',
    width: '100%',
    height: '100%',
    top: '0',
    backgroundColor: '#444444',
    opacity: '0.6'
}

const audioPlayerDiv = {
    position: 'fixed',
    zIndex: '1000',
    width: '600px',
    height: '500px',
    backgroundColor: '#dddddd',
    left: '50%',
    top: '24%',
    borderRadius: '8px',
    transform: 'translate(-50%, 0)'
}

const audioPlayerDivMobile = {
    position: 'fixed',
    zIndex: '1000',
    width: '90%',
    height: '500px',
    backgroundColor: '#dddddd',
    left: '50%',
    top: '24%',
    borderRadius: '8px',
    transform: 'translate(-50%, 0)'
}

const topWrapper = {
    maxHeight: '360px',
    overflowY: 'auto'
}

const headerWrapper = {
    width: '90%',
    margin: 'auto',
    textAlign: 'center'
}

const audioPlayerHeader = {
    fontFamily: 'Lato, sans-serif',
    fontSize: '32px',
    fontWeight: '400',
    margin: '24px auto 14px auto'
}

const audioPlayerHeaderMobile = {
    ...audioPlayerHeader,
    fontSize: '24px',
    fontWeight: '400',
}

const horizontalDivider = {
    width: '82%',
    height: '1px',
    margin: 'auto',
    backgroundColor: '#bbb'
}

const descriptionWrapper = {
    width: '90%',
    margin: '10px auto 10px auto',
    textAlign: 'justify',
    overflowY: 'auto',
}

const bottomWrapper = {
    position: 'absolute',
    width: '90%',
    left: '5px',
    top: '368px'
}

const detailsWrapper = {
    width: '90%',
    margin: 'auto',
    textAlign: 'left',
}

const audioPlayerDescription = {
    fontFamily: 'Lato, sans-serif',
    fontSize: '22px',
    fontWeight: '300', 
}

const audioPlayerDescriptionMobile = {
    ...audioPlayerDescription,
    fontSize: '18px',
}

const audioPlayerDetails = {
    fontFamily: 'Lato, sans-serif',
    fontSize: '20px',
    fontWeight: '300', 
    color: '#555',
}

const audioPlayerDetailsMobile = {
    ...audioPlayerDetails,
    fontSize: '18px',
}

const playerStyle = {
    color: '#fb7454',
    width: '90%',
    margin: '14px 0px 10px 28px',
}

export default AudioPlayer;