import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';

import { Breakpoint } from 'react-socks';

import Truncate from 'react-truncate';

import FontAwesome from 'react-fontawesome';

class Episode extends Component {

    constructor(props) {
        super(props);
        this.seasonRef = React.createRef();
        this.citationRef = React.createRef();
        this.state = { 
            season: props.episode.season,
            color: props.color,
            citationVisible: 'false'
        }

        this.assignColor = this.assignColor.bind(this);
        this.toggleCitation = this.toggleCitation.bind(this);
    }

    componentDidMount() {
        this.assignColor()
    }

    assignColor() {
        console.log(this.state.color)
        this.seasonRef.current.style.backgroundColor = this.state.color;
    }

    showCitation() {
        this.citationRef.current.style.visibility = 'visible';
    }

    hideCitation() {
        this.citationRef.current.style.visibility = 'hidden';
    }

    toggleCitation(e) {
        e.stopPropagation();
        if (this.state.citationVisible == 'false') {
            this.setState({
                citationVisible: 'true'
            }, this.showCitation)
        } else {
            this.setState({
                citationVisible: 'false'
            }, this.hideCitation)
        }
    }

    render() {
        return(
            <Fragment>
            <Breakpoint customQuery="(min-width: 450px)">
            <div style={episodeDiv} onClick={(e) => { this.props.showAudioPlayer(e); this.props.setCurrentEpisode(this.props.episode)}}>
                <div style={citationWindowWrapper} ref={this.citationRef}>
                    <div style={citationFilter} onClick={(e) => {this.toggleCitation(e);}}/>
                    <div style={citationWindow} onClick={(e) => {e.stopPropagation();}}>
                        <p style={citationTitle}>Citation for Episode</p>
                        <p style={citationText}>Giraudy, Agustina and Lupu, Noam. {this.props.episode.title}. <i>Agora Podcast.</i> Podcast Audio. <Moment date={this.props.episode.date} format="MMMM D, YYYY" />.</p>
                        <div style={citationClose}  onClick={(e) => {this.toggleCitation(e);}}>
                            <FontAwesome name='times' size="2x"
                            />
                        </div>
                    </div>
                </div>
                <div style={imageAndDescriptionWrapper}>
                    <div style={headerDiv}>
                        <p style={episodeTitle}>{this.props.episode.title}</p>
                    </div>

                    <div style={episodeImageWrapper}>
                        <div style={seasonTag} ref={this.seasonRef}>
                            <h1 style={seasonTagFont}>Season {this.props.episode.season}</h1>
                        </div>
                        <img src={process.env.PUBLIC_URL + `/images/${this.props.episode.image_filename}`} alt={this.props.episode.title} style={episodeImage} />
                    </div>

                        <div style={descriptionDiv}>
                        <p>
                        <Truncate lines={9} ellipsis={<span>...</span>}>
                            {this.props.episode.description}
                        </Truncate>
                        </p>
                    </div>
                </div>
                <div style={detailsDiv}>
                    <p><Moment date={this.props.episode.date} format="MMMM D, YYYY" /></p>
                    <p>Language: {this.props.episode.language}</p>
                    <div style={citationDiv} onClick={(e) => {this.toggleCitation(e);}}>
                        Cite this episode
                    </div>
                </div>  
            </div>
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 449px)">
            <div style={episodeDivMobile} onClick={() => { this.props.showAudioPlayer(); this.props.setCurrentEpisode(this.props.episode)}}>
                <div style={headerDiv}>
                    <h1 style={episodeTitleMobile}>{this.props.episode.title}</h1>
                </div>

                <div style={imageAndDescriptionWrapperMobile}>
                    <div style={episodeImageWrapperMobile}>
                        <div style={seasonTag} ref={this.seasonRef}>
                            <h1 style={seasonTagFont}>Season {this.props.episode.season}</h1>
                        </div>
                        <img src={process.env.PUBLIC_URL + `/images/${this.props.episode.image_filename}`} alt={this.props.episode.title} style={episodeImageMobile} />
                    </div>

                        <div style={descriptionDiv}>
                        <p>
                        <Truncate lines={10} ellipsis={<span>...</span>}>
                            {this.props.episode.description}
                        </Truncate>   
                        </p>
                    </div>
                </div>
                <div style={detailsDiv}>
                    <p><Moment date={this.props.episode.date} format="MMMM D, YYYY" /></p>
                    <p>Language: {this.props.episode.language}</p>
                    <div style={citationDivMobile}>
                        Cite this episode
                    </div>
                </div>  
            </div>
            </Breakpoint>
            </Fragment>
        )
    }

}

const episodeDiv = {
    width: '380px',
    backgroundColor: '#e6e6e6',
    margin: '20px 20px 20px 20px',
    overflow: 'hidden',
    borderRadius: '6px',
    cursor: 'pointer',
    height: '568px',
    position: 'relative'
}

const episodeDivMobile = {
    ...episodeDiv,
    width: '300px',
}

const headerDiv = {
    textAlign: 'center',
    overflow: 'hidden',
    width: '90%',
    maxHeight: '96px',
    margin: 'auto',
    padding: '6px 0px 14px 0px',
}

const seasonTag = {
    height: '30px',
    width: '140px',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '11px',
    color: '#222222',
    backgroundColor: 'yellow',
    borderRadius: '0px',
    position: 'absolute',
    borderRadius: '4px 0px 22px 0px',
}

const seasonTagFont = {
    marginTop: '4px'
}

const imageAndDescriptionWrapper = {
    width: '100%',
    maxHeight: '510px',
    overflow: 'hidden',
}

const imageAndDescriptionWrapperMobile = {
    width: '100%',
    maxHeight: '440px',
    overflow: 'hidden',
}

const episodeImageWrapper = {
    width: '340px',
    height: '200px',
    margin: 'auto',
    overflow: 'hidden',
    borderRadius: '4px',
}

const episodeImage = {
    width: '340px',
    height: '200px',
    objectFit: 'cover'
}

const episodeImageWrapperMobile = {
    ...episodeImageWrapper,
    width: '280px',
    height: '180px'
}

const episodeImageMobile = {
    ...episodeImage,
    width: '280px',
    height: '180px',
}

const descriptionDiv = {
    margin: '6px auto 4px auto',
    width: '90%',
    textAlign: 'justify',
    overflow: 'hidden',
}

const detailsDiv = {
    width: '340px',
    height: '48px',
    color: '#444',
    fontSize: '16px',
    textAlign: 'left',
    position: 'absolute',
    top: '518px',
    left: '20px'
}

const citationDiv = {
    width: '100px',
    height: '48px',
    fontSize: '16px',
    color: '#666',
    textAlign: 'left',
    position: 'absolute',
    bottom: '2px',
    left: '84%'
}

const citationDivMobile = {
    ...citationDiv,
    fontSize: '16px',
    textAlign: 'right',
    position: 'absolute',
    left: '48%'
}

const citationWindowWrapper = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    visibility: 'hidden',
    cursor: 'default'
}

const citationFilter = {
    width: '100%',
    height: '100%',
    backgroundColor: '#444',
    position: 'absolute',
    zIndex: '100',
    opacity: '0.2'
}

const citationWindow = {
    width: '92%',
    backgroundColor: '#e6e6e6',
    position: 'absolute',
    zIndex: '120',
    left: '4%',
    top: '50%',
    opacity: '1.0',
    borderRadius: '4px',
    border: '1px solid #888',
    transform: 'translate(0, -50%)',
}

const citationTitle = {
    width: '90%',
    margin: '10px auto 16px auto'
}

const citationText = {
    width: '90%',
    margin: '0px auto 16px auto'
}

const citationClose = {
    width: '40px',
    height: '40px',
    position: 'absolute',
    left: '100%',
    top: '10px',
    opacity: '1.0',
    transform: 'translate(-100%, 0)',
    fontSize: '10px',
    color: '#333',
    cursor: 'pointer'
}

// FONTS

const episodeTitle = {
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '28px',
    color: '#222222'
}

const episodeTitleMobile = {
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '24px',
    color: '#222222'
}


export default Episode;