import React, { Component, Fragment } from 'react';

import Episodes from './Episodes'
import SeasonSelect from './SeasonSelect'
import SocialMedia from './SocialMedia'

class EpisodesContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            selected_season: 0,
            episodes_shown: 4
        };

        this.loadMore = this.loadMore.bind(this);
    }

    filterSeason = (id) => {
        console.log(id)
        this.setState({
            selected_season: id
        }, () => {
            console.log(this.state.selected_season);
        }); 
    }

    loadMore() {
        this.setState({
            episodes_shown: this.state.episodes_shown + 4
        })
    }

    resetEpisodesShown = () => {
        this.setState({
            episodes_shown: 4
        })
    }

    render() {
        return(
            <Fragment>
                <div style={episodesContainerWrapper}>
                    <div style={episodesContainerDiv}>
                        <div style={episodesContainerLeft}>
                            <h1 style={episodesContainerFont}>Episodes</h1>

                            <SeasonSelect filterSeason={this.filterSeason} resetEpisodesShown={this.resetEpisodesShown} episodes={this.props.episodes}/>

                            <Episodes episodes={this.props.episodes} showAudioPlayer={this.props.showAudioPlayer} setCurrentEpisode={this.props.setCurrentEpisode} selected_season={this.state.selected_season} loadMore={this.loadMore} episodes_shown={this.state.episodes_shown}/>
                        </div>
                        <div style={episodesContainerRight}>
                            <SocialMedia />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const episodesContainerWrapper = {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
}

const episodesContainerDiv = {
    maxWidth: '1300px',
    margin: '20px auto 40px auto',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
}

const episodesContainerLeft = {
    maxWidth: '900px',
}

const episodesContainerRight = {
    width: '300px'
}

const episodesContainerFont = {
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '52px',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    marginBottom: '20px'
}

export default EpisodesContainer;