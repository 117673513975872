import React, { Component, Fragment } from 'react';

import { Link } from 'react-scroll';

import { Link as RouteLink } from 'react-router-dom';

import { Breakpoint } from 'react-socks';

import Text from 'react-text';
import dictionary from './Dictionary';

class Header extends Component {

    constructor(props) {
        super(props);
        this.dropdownRef = React.createRef();
    }

    darkenAnimation(e) {
        this.dropdownRef.current.style.visibility = 'visible';
        this.dropdownRef.current.style.opacity = '1.0';
    }
    
    lightenAnimation(e) {
        this.dropdownRef.current.style.opacity = '0.0';
        this.dropdownRef.current.style.visibility = 'hidden';
    }  

    render() {
        return(
            <div style={headerWrapperDiv}>
                <div style={headerDiv}>
                    <div style={logoDiv}>
                    <img src={process.env.PUBLIC_URL + `/images/logo.jpg`} alt='Logo' style={logoImage} />
                    <Breakpoint customQuery="(min-width: 450px)">
                        <p style={logoFont} onselectstart="return false">
                            AGORA
                        </p>
                    </Breakpoint>
                    <Breakpoint customQuery="(max-width: 449px) and (min-width: 340px)">
                        <p style={logoFontMobile} onselectstart="return false">
                            AGORA PODCAST
                        </p>
                    </Breakpoint>
                    <Breakpoint customQuery="(max-width: 339px)">
                        <p style={logoFontMobileSmall} onselectstart="return false">
                            AGORA PODCAST
                        </p>
                    </Breakpoint>
                        
                    </div>
                    <Breakpoint customQuery="(min-width: 1180px)">
                    <div style={linksDiv}>
                        <Link to="about" spy={true} smooth={true} offset={0} duration={500}>
                            <div style={linkDiv}>
                                <div style={linkMiddleDiv} className='headeritem'>
                                    <Text language={this.props.language} dictionary={dictionary}>
                                        <Text aboutButton />
                                    </Text>
                                </div>
                            </div>
                        </Link>
                        <Link to="episodes" spy={true} smooth={true} offset={0} duration={500}>
                            <div style={linkDiv}>
                                <div style={linkMiddleDiv} className='headeritem'>
                                    <Text language={this.props.language} dictionary={dictionary}>
                                        <Text episodesButton />
                                    </Text>
                                </div>
                            </div>
                        </Link>
                        <div style={dropdownWrapper} onMouseEnter={this.darkenAnimation.bind(this)} onMouseLeave={this.lightenAnimation.bind(this)}>
                            <div style={largeLinkDiv} className='headeritem'>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text whereToListenButton />
                                </Text>
                                <img src={process.env.PUBLIC_URL + `/images/icons/down.png`} alt='down chevron' style={dropdownIcon} />
                                <div style={dropdownMenu} ref={this.dropdownRef} className='dropdown'>
                                <a href='https://open.spotify.com/' target='_blank'>
                                    <div style={dropdownElement}>
                                        Spotify
                                    </div>
                                </a>
                                <a href='https://apps.apple.com/us/app/apple-podcasts/id525463029' target='_blank'>
                                    <div style={dropdownElement}>
                                        Apple
                                    </div>
                                </a>
                            </div>
                            </div>
                        </div>
                        { this.props.language == 'en' && 
                            <RouteLink to='/es'>
                            <div style={languageDiv} onClick={this.props.changeLanguage}  className='headeritem'>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </div>
                            </RouteLink>
                        }
                        { this.props.language == 'es' && 
                            <RouteLink to='/'>
                            <div style={languageDiv} onClick={this.props.changeLanguage} className='headeritem'>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </div>
                            </RouteLink>
                        }
                    </div>
                    </Breakpoint>
                    <Breakpoint customQuery="(max-width: 1179px) and (min-width: 990px)">
                    <div style={linksDivSmall}>
                        <Link to="about" spy={true} smooth={true} offset={0} duration={500}>
                            <div style={linkDivSmall}>
                                <div style={linkMiddleDivSmall} className='headeritem'>
                                    <Text language={this.props.language} dictionary={dictionary}>
                                        <Text aboutButton />
                                    </Text>
                                </div>
                            </div>
                        </Link>
                        <Link to="episodes" spy={true} smooth={true} offset={0} duration={500}>
                            <div style={linkDivSmall}>
                                <div style={linkMiddleDivSmall} className='headeritem'>
                                    <Text language={this.props.language} dictionary={dictionary}>
                                        <Text episodesButton />
                                    </Text>
                                </div>
                            </div>
                        </Link>
                        <div style={dropdownWrapper} onMouseEnter={this.darkenAnimation.bind(this)} onMouseLeave={this.lightenAnimation.bind(this)}>
                            <div style={largeLinkDivSmall} className='headeritem'>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text whereToListenButton />
                                </Text>
                                <img src={process.env.PUBLIC_URL + `/images/icons/down.png`} alt='down chevron' style={dropdownIconSmall} />
                                <div style={dropdownMenuSmall} ref={this.dropdownRef} className='dropdown'>
                                <a href='https://open.spotify.com/' target='_blank'>
                                    <div style={dropdownElementSmall}>
                                        Spotify
                                    </div>
                                </a>
                                <a href='https://apps.apple.com/us/app/apple-podcasts/id525463029' target='_blank'>
                                    <div style={dropdownElementSmall}>
                                        Apple
                                    </div>
                                </a>
                            </div>
                            </div>
                        </div>
                        { this.props.language == 'en' && 
                            <RouteLink to='/es'>
                            <div style={languageDivSmall} onClick={this.props.changeLanguage} className='headeritem'>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </div>
                            </RouteLink>
                        }
                        { this.props.language == 'es' && 
                            <RouteLink to='/'>
                            <div style={languageDivSmall} onClick={this.props.changeLanguage} className='headeritem'>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </div>
                            </RouteLink>
                        }
                    </div>
                    </Breakpoint>
                </div>
            </div>
        )
    }
}

const headerWrapperDiv = {
    width: '100%',
    height: '100px',
    backgroundColor: '#1b2133',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
}

const headerWrapperDivSkinny = {
    ...headerWrapperDiv,
    height: '100px'
}

const headerDiv = {
    maxWidth: '1600px',
    margin: 'auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    overflow: 'hidden'
}

const logoDiv = {
    maxWidth: '400px',
    padding: '0px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    flexShrink: '0',
    marginRight: '40px'
}

const logoImage = {
    width: '100px',
    height: '100px',
    marginRight: '30px'
}

const logoFont = {
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '82px',
    letterSpacing: '2px',
    color: 'lightblue',
    position: 'relative'
}

const logoFontMobile = {
    ...logoFont,
    fontSize: '42px',
    width: '120px',
    right: '10px'
}

const logoFontMobileSmall = {
    ...logoFont,
    fontSize: '34px',
    lineHeight: '32px',
    width: '120px',
    right: '14px'
}

const linksDiv = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: '700px',
    flex: '1 1 auto',
    paddingRight: '140px',
}

const linksDivSmall = {
    ...linksDiv,
    width: '500px',
    flex: '1 1 auto',
    paddingRight: '140px',
}

const linkDiv = {
    width: '140px',
    height: '100%',
    color: 'lightblue',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '34px',
    textAlign: 'center',
    marginRight: 'auto',
    cursor: 'pointer',
}

const linkDivSmall = {
    ...linkDiv,
    width: '100px',
    fontSize: '28px',
}

const linkMiddleDiv = {
    width: '100%',
    margin: '35% 0 50% 0',
    transform: 'translate(0, -50%)'
}

const linkMiddleDivSmall = {
    width: '100%',
    margin: '35% 0 50% 0',
}

const largeLinkDiv = {
    width: '140px',
    height: '100%',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '34px',
    textAlign: 'left',
    margin: '16px 0px 0px 24px',
    cursor: 'pointer',
}

const largeLinkDivSmall = {
    ...largeLinkDiv,
    height: '50%',
    fontSize: '28px',
    margin: '35px 0px 0px 0px',
}

const languageDiv = {
    width: '120px',
    height: '100%',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '35px',
    textAlign: 'center',
    margin: '16px 0px 0px 0px',
    cursor: 'pointer',
}

const languageDivSmall = {
    ...languageDiv,
    width: '100px',
    fontSize: '28px',
    marginTop: '36px'
}

const dropdownWrapper = {
    
}

const dropdownIcon = {
    height: '30px',
    position: 'absolute',
    top: '56px',
    marginLeft: '8px',
    filter: 'invert(91%) sepia(9%) saturate(1453%) hue-rotate(164deg) brightness(94%) contrast(91%)'
}

const dropdownIconSmall = {
    height: '26px',
    position: 'absolute',
    top: '50px',
    marginLeft: '8px',
    filter: 'invert(91%) sepia(9%) saturate(1453%) hue-rotate(164deg) brightness(94%) contrast(91%)'
}

const dropdownMenu = {
    width: '200px',
    height: '140px',
    position: 'relative',
    right: '50px',
    zIndex: '3000',
    backgroundColor: '#1b2133',
    visibility: 'hidden',
    opacity: '0.0',
    transition: 'opacity 0.2s',
    transitionTimingFunction: 'ease-out',
    color: 'lightblue',
    fontFamily: 'Arquitecta, sans-serif',
    fontSize: '34px',
    textAlign: 'center',
}

const dropdownMenuSmall = {
    ...dropdownMenu,
    top: '7px',
    right: '30px',
    height: '130px',
    width: '160px'
}

const dropdownElement = {
    height: '40px',
    width: '100%',
    paddingTop: '20px'
}

const dropdownElementSmall = {
    height: '34px',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '28px'
}

export default Header;