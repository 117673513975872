import React, { Component, Fragment } from 'react';
import Dropdown from './Dropdown'
import Episodes from './Episodes';

class SeasonSelect extends Component {

    constructor(){
        super()
        this.state = {
          latestSeason: 0,
          seasons: [
            {
              id: 0,
              title: 'All Seasons',
              selected: false,
              key: 'seasons'
            }
          ]
        }
        this.addSeasons = this.addSeasons.bind(this);
      }

    componentDidMount() {
        window.addEventListener('keydown', this.tabKeyPressed);
        window.addEventListener('mousedown', this.mouseClicked);
        this.addSeasons();
    }
    
    tabKeyPressed = (e) => {
        if (e.keyCode === 9) {
          document.querySelector('body').classList.remove('noFocus');
          window.removeEventListener('keydown', this.tabKeyPressed);
          window.addEventListener('mousedown', this.mouseClicked);
        }
    }
    
    mouseClicked = (e) => {
        document.querySelector('body').classList.add('noFocus');
        window.removeEventListener('mousedown', this.mouseClicked);
        window.addEventListener('keydown', this.tabKeyPressed);
    }
    
      toggleItem = (id, key) => {
        const temp = JSON.parse(JSON.stringify(this.state[key]));
        temp[id].selected = !temp[id].selected;
        this.setState({
          [key]: temp,
        });
      }
    
      resetThenSet = (id, key) => {
        const { filterSeason, resetEpisodesShown } = this.props;

        const temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach((item) => item.selected = false);
        temp[id].selected = true;
        
        this.setState({
          [key]: temp,
        }, filterSeason(id), resetEpisodesShown());
      }

      addSeasons = () => {
        var tempAdd = []
        var latestSeason = 0

        this.props.episodes.forEach((epi) => {
          if (parseInt(epi.season, 10) > latestSeason) {
            tempAdd.push({
                id: parseInt(epi.season, 10),
                title: ('Season ' + epi.season),
                selected: false,
                key: 'seasons'
            })
            
            latestSeason = latestSeason + 1
          }
        })
          
        console.log(tempAdd)
        
        this.setState(prevState => ({
          seasons: [
          ...prevState.seasons,
          ...tempAdd
          ]
        }))
      }

    render() {
        return(
            <div style={dropdownWrapper}>
                <Dropdown
                    title="Select Season"
                    list={this.state.seasons}
                    resetThenSet={this.resetThenSet}
                />
            </div>
           
        )
    }
}

const dropdownWrapper = {
    maxWidth: '820px',
    height: '60px',
    margin: 'auto',
}

export default SeasonSelect