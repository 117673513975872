import React, { Component, Fragment } from 'react';

import { Breakpoint } from 'react-socks';

import Text from 'react-text';
import dictionary from '../Dictionary';

class SlideContents extends Component {

    constructor(props) {
        super(props);
        this.dimmerRef = React.createRef();
    }

    preventDragHandler = (e) => {
        e.preventDefault();
    }

    darkenAnimation(e) {
        this.dimmerRef.current.style.opacity = '0.3';
    }

    lightenAnimation(e) {
        this.dimmerRef.current.style.opacity = '0.0';
    }    

    render() {
        return (
            <Fragment>
            <Breakpoint customQuery="(min-width: 700px)">
            <div className="each-slide" style={slideDiv} onClick={() => { this.props.showAudioPlayer(); this.props.setCurrentEpisode(this.props.episode)}} >    
                <div style={dimmerCover} ref={this.dimmerRef}/>
                <div style={slideImageWrapper}  onMouseEnter={this.darkenAnimation.bind(this)} onMouseLeave={this.lightenAnimation.bind(this)}>
                    <div style={slideCover}>
                        <div style={slideCoverContents}>
                            <div style={highlightTitle}>
                                <p>
                                    <Text language={this.props.language} dictionary={dictionary}>
                                        <Text episodeWord />
                                    </Text>: {this.props.episode.title}
                                </p>
                            </div>
                            <div style={highlightDescription}>
                                <p>{this.props.episode.description}</p>
                            </div>
                        </div>
                    </div>
                    <img src={process.env.PUBLIC_URL + `/images/episode_${this.props.episode.id}.${this.props.episode.image_type}`} style={slideImage} alt={this.props.episode.title} onDragStart={this.preventDragHandler} />
                </div>
            </div>
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 699px)">
            <div className="each-slide" style={slideDivMobile} onClick={() => { this.props.showAudioPlayer(); this.props.setCurrentEpisode(this.props.episode)}} >    
                <div style={dimmerCover} ref={this.dimmerRef}/>
                <div style={slideImageWrapper}  onMouseEnter={this.darkenAnimation.bind(this)} onMouseLeave={this.lightenAnimation.bind(this)}>
                    <div style={slideCover}>
                        <div style={slideCoverContentsMobile}>
                            <div style={highlightTitleMobile}>
                                <p>Episode: {this.props.episode.title}</p>
                            </div>
                            <div style={highlightDescriptionMobile}>
                                <p>{this.props.episode.description}</p>
                            </div>
                        </div>
                    </div>
                    <img src={process.env.PUBLIC_URL + `/images/episode_${this.props.episode.id}.${this.props.episode.image_type}`} style={slideImage} alt={this.props.episode.title} onDragStart={this.preventDragHandler} />
                </div>
            </div>
            </Breakpoint>
            </Fragment>
        )
    } 
};

const slideDiv = {
    height: '650px'
}

const slideDivMobile = {
    height: '300px'
}

const slideImageWrapper = {
    width: '100%',
    height: '100%',
    position: 'relative',
}

const slideImage = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
}

const dimmerCover = {
    width: '100%',
    height: '650px',
    position: 'absolute',
    zIndex: '500',
    transition: 'opacity 0.8s',
    opacity: '0.0',
    backgroundColor: 'black'
}


const slideCover = {
    width: '100%',
    height: '650px',
    position: 'absolute',
    zIndex: '600',
    backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.4) 80%)',
    cursor: 'pointer',
}

const slideCoverContents = {
    maxWidth: '1000px',
    margin: '80px auto',
    padding: '0px 60px 0px 60px',
    opacity: '1.0',
}

const slideCoverContentsMobile = {
    ...slideCoverContents,
    margin: '20px auto',
    padding: '0px 10px 0px 40px',
}

const highlightTitle = {
    color: 'white',
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '50px',
    marginBottom: '40px'
}

const highlightTitleMobile = {
    ...highlightTitle,
    fontSize: '30px',
    lineHeight: '30px',
}

const highlightDescription = {
    height: '400px',
    color: 'white',
    textAlign: 'justify',
    fontFamily: 'Garamond, serif',
    fontSize: '22px',
    fontWeight: '100',
    marginRight: '80px',
    position: 'relative',
    bottom: '30px'
}

const highlightDescriptionMobile = {
    ...highlightDescription,
    marginRight: '30px',
    fontSize: '18px',
    bottom: '20px'
}


export default SlideContents;