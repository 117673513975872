import React, { Component, Fragment } from 'react';

import { Breakpoint } from 'react-socks';

import { Link as RouteLink } from 'react-router-dom';

import Text from 'react-text';
import dictionary from './Dictionary';

class Footer extends Component {
    render() {
        return(
            <Fragment>
            <Breakpoint customQuery="(min-width: 450px)">
            <div style={footerDiv}>
                <div style={logoDiv}>
                    <img src={process.env.PUBLIC_URL + `/images/logo.jpg`} alt='Logo' style={logoImage} />
                </div>
                <div style={horizontalDivider} />
                <div style={cpDiv}>
                    <p style={cpFont}>Copyright 2020</p>
                </div>
                <div style={cpDiv}>
                    { this.props.language == 'en' && 
                        <RouteLink to='/es'>
                            <p style={cpFontLanguage} onClick={this.props.changeLanguage}>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </p>
                        </RouteLink>
                    }
                    { this.props.language == 'es' && 
                        <RouteLink to='/'>
                            <p style={cpFontLanguage} onClick={this.props.changeLanguage}>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </p>
                        </RouteLink>
                    }
                </div>
            </div>
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 449px)">
            <div style={footerDivMobile}>
                <div style={logoDivMobile}>
                    <img src={process.env.PUBLIC_URL + `/images/logo.jpg`} alt='Logo' style={logoImageMobile} />
                </div>
                <div style={horizontalDividerMobile} />
                <div style={cpDiv}>
                    <p style={cpFont}>Copyright 2020</p>
                </div>
                <div style={cpDiv}>
                    { this.props.language == 'en' && 
                        <RouteLink to='/es'>
                            <p style={cpFontLanguage} onClick={this.props.changeLanguage}>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </p>
                        </RouteLink>
                    }
                    { this.props.language == 'es' && 
                        <RouteLink to='/'>
                            <p style={cpFontLanguage} onClick={this.props.changeLanguage}>
                                <Text language={this.props.language} dictionary={dictionary}>
                                    <Text changeLanguageButton />
                                </Text>
                            </p>
                        </RouteLink>
                    }
                </div>
            </div>
            </Breakpoint>
            </Fragment>
        )
    }
}

const footerDiv = {
    width: '100%',
    height:  '520px',
    backgroundColor: '#1b2133',
    display: 'flex',
    flexFlow: 'column nowrap',
}

const footerDivMobile = {
    ...footerDiv,
    height:  '420px',
}

const logoDiv = {
    width: '340px',
    height: '340px',
    position: 'relative',
    margin: '40px auto 0px auto',
}

const logoDivMobile = {
    ...logoDiv,
    width: '240px',
    height: '240px',
    margin: 'auto',
}

const logoImage = {
    width: '340px',
    height: '340px'
}

const logoImageMobile = {
    width: '240px',
    height: '240px'
}

const horizontalDivider = {
    width: '340px',
    height: '1px',
    margin: '30px auto 20px auto',
    backgroundColor: '#777'
}

const horizontalDividerMobile = {
    width: '240px',
    height: '1px',
    margin: '0px auto 20px auto',
    backgroundColor: '#777'
}

const cpDiv = {
    margin: '0px auto 0px auto',
    height: '40px',
}

const cpFont = {
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '22px',
    letterSpacing: '2px',
    color: 'white',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none"
}

const cpFontLanguage = {
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '22px',
    letterSpacing: '2px',
    color: 'white',
    cursor: 'pointer'
}

export default Footer;