export default {
    aboutButton: {
        en: 'About',
        es: 'Sobre Agora'
    },
    episodesButton: {
        en: 'Episodes',
        es: 'Episodios'
    },
    whereToListenButton: {
        en: 'Where to Listen',
        es: 'Donde Escuchar'
    },
    changeLanguageButton: {
        en: 'En Español',
        es: 'In English'
    },
    episodeWord: {
        en: 'Episode',
        es: 'Episodio'
    },
    episodesWord: {
        en: 'Episodes',
        es: 'Episodios'
    },
    aboutAgora: {
        en: 'About Agora',
        es: 'Sobre Agora'
    },
    selectSeason: {
        en: 'Select Season',
        es: 'Eligi Temporada'
    }
}