import React, { Component } from 'react';

import { Breakpoint } from 'react-socks';

import Text from 'react-text';
import dictionary from './Dictionary';

class About extends Component {

    render() {

        const descriptionText = 
            <p>Agora helps listeners make sense of current events in Latin America and the Caribbean. In each episode, <a href="https://www.american.edu/sis/faculty/giraudy.cfm" target="_blank">Agustina Giraudy</a> and <a href="https://www.noamlupu.com/" target="_blank">Noam Lupu</a>, professors of political science at American University and Vanderbilt University, interview a broad range of academics and researchers. Agora’s guests will use their  expertise and research to provide a timely and nuanced analysis of major political trends and events in the region. Unlike other podcasts discussing the region, Agora provides content that is at once timely and founded in evidence and the cutting edge of scientific knowledge.</p>

        return(
            <div style={aboutWrapper}>
                <div style={aboutDiv}>
                    <h1 style={aboutHeaderFont}>
                        <Text language={this.props.language} dictionary={dictionary}>
                            <Text aboutAgora />
                        </Text>
                    </h1>

                    <div style={horizontalDivider} />
                    <Breakpoint customQuery="(min-width: 950px)">
                    <div style={aboutContents}>
                        <div style={aboutDescription}>
                            {descriptionText}
                        </div>
                        <div style={imagesWrapper}>
                            <div style={aboutImageWrapper}>
                                <img src={process.env.PUBLIC_URL + `/images/agustina_giraudy.jpg`} alt='Agustina Giraudy' style={aboutImage} />
                            </div>
                            <div style={aboutImageWrapper}>
                                <img src={process.env.PUBLIC_URL + `/images/noam_lupu.jpg`} alt='Noam Lupu' style={aboutImage} />
                            </div>
                        </div>
                    </div>
                </Breakpoint>
                <Breakpoint customQuery="(max-width: 949px) and (min-width: 450px)">
                    <div style={aboutContentsMedium}>
                        <div style={aboutDescription}>
                            {descriptionText}
                        </div>
                        <div style={imagesWrapperMedium}>
                            <div style={aboutImageWrapper}>
                                <img src={process.env.PUBLIC_URL + `/images/agustina_giraudy.jpg`} alt='Agustina Giraudy' style={aboutImage} />
                            </div>
                            <div style={aboutImageWrapper}>
                                <img src={process.env.PUBLIC_URL + `/images/noam_lupu.jpg`} alt='Noam Lupu' style={aboutImage} />
                            </div>
                        </div>
                    </div>
                </Breakpoint>
                <Breakpoint customQuery="(max-width: 449px)">
                    <div style={aboutContentsMedium}>
                        <div style={aboutDescriptionMobile}>
                            {descriptionText}
                        </div>
                        <div style={imagesWrapperMedium}>
                            <div style={aboutImageWrapperMobile}>
                                <img src={process.env.PUBLIC_URL + `/images/agustina_giraudy.jpg`} alt='Agustina Giraudy' style={aboutImageMobile} />
                            </div>
                            <div style={aboutImageWrapperMobile}>
                                <img src={process.env.PUBLIC_URL + `/images/noam_lupu.jpg`} alt='Noam Lupu' style={aboutImageMobile} />
                            </div>
                        </div>
                    </div>
                </Breakpoint>
                    
                </div>
            </div>
        )
    }
}

const aboutWrapper = {
    width: '100%',
    backgroundColor: '#dddddd'
}

const aboutBanner = {
    width: '100%',
    height: '18px',
    backgroundColor: '#1b2133',
}

const aboutDiv = {
    maxWidth: '1200px',
    margin: '0px auto 0px auto',
    padding: '46px 30px 20px 30px',
    textAlign: 'justify',
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '30px',
}

const aboutHeaderFont = {
    fontFamily: 'ArquitectaBook, sans-serif',
    fontSize: '52px',
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '20px',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none"
}

const horizontalDivider = {
    width: '260px',
    height: '1px',
    margin: '0px auto 20px auto',
    backgroundColor: '#bbb'
}

const aboutContents = {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: '30px'
}

const aboutContentsMedium = {
    display: 'flex',
    flexFlow: 'column nowrap'
}

const aboutDescription = {
    
}

const aboutDescriptionMobile = {
    fontSize: '24px',
    textAlign: 'center',
    
}

const imagesWrapper = {
    display: 'flex',
    flexFlow: 'row nowrap',
    margin: '34px 0px 0px 30px'
}

const imagesWrapperMedium = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'spaceBetween',
    margin: '34px auto'
}

const aboutImageWrapper = {
    height: '230px',
    width: '180px',
    marginLeft: '20px'
}

const aboutImage = {
    height: '230px',
    width: '180px',
    objectFit: 'cover',
    borderRadius: '6px'
}

const aboutImageWrapperMobile = {
    height: '180px',
    width: '120px',
    marginLeft: '10px',
    marginRight: '10px'
}

const aboutImageMobile = {
    height: '160px',
    width: '120px',
    objectFit: 'cover',
    borderRadius: '6px'
}

export default About;